import { computed, reactive } from '@vue/composition-api'

const mergeValidationIgnoreKeyList = [
  '$anyInvalid',
  '$dirty',
  '$errors',
  '$value',
  '$reset',
  '$touch',
  'toObject',
]

export function useMergeValidationHelper(value, ...validation) {
  const $anyInvalid = computed(() => {
    for (const validator of validation) {
      if (validator?.$anyInvalid) {
        return true
      }
    }
    return false
  })

  const $dirty = computed(() => {
    for (const validator of validation) {
      if (validator?.$dirty) {
        return true
      }
    }
    return false
  })

  const $errors = computed(() => {
    return validation.reduce((acc, item) => {
      return item.$errors ? acc.concat(item.$errors) : acc
    }, [])
  })
  const $value = computed(() => value.value)

  const mergedValidation = {
    $anyInvalid,
    $dirty,
    $value,
    $errors,
  }

  // Loop through validation and add validators
  // Builds a complete validation object viewable in vue-devtools
  for (const validator of validation) {
    if (validator) {
      Object.keys(validator).forEach((key) => {
        if (mergeValidationIgnoreKeyList.indexOf(key) === -1) {
          mergedValidation[key] = validator[key]
        }
      })
    }
  }

  return reactive(mergedValidation)
}
