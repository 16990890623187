import { ref, computed, getCurrentInstance, reactive } from '@vue/composition-api'
import { useComponentId } from '@/composables/useComponent'
import { useValidationHelpers } from '@/composables/useValidationHelpers'
import { useMergeValidationHelper } from '@/composables/useMergeValidationHelper'
import { omit } from 'lodash-es'

export function useInputHelpers(instance = getCurrentInstance(), $props = {}) {
  // Make some reactive instance parts
  const attrs = computed(() => instance.proxy.$attrs)
  const props = computed(() => instance.proxy.$props)
  const localProps = computed(() => $props)
  const listeners = computed(() => instance.proxy.$listeners)
  const emit = instance.emit
  const event = instance.proxy.$options.model?.event || 'input'

  const resolveBooleanAttr = (attr) => (attr === '' ? true : attr)

  const validation = useMergeValidationHelper(
    props.value,
    props.value.validation,
    localProps.value.componentValidation
  )

  const validationHelpers = useValidationHelpers(validation)

  // Root
  const rootClasses = computed(() => {
    return {
      input: true,
      'input--disabled': resolveBooleanAttr(attrs.value.disabled),
      'input--readonly': resolveBooleanAttr(attrs.value.readonly),
      'input--error': validationHelpers?.hasError,
    }
  })

  // Wrapper
  const wrapperClasses = ['input-wrapper']

  // Label
  const labelId = useComponentId('label')
  const labelClasses = ['input-label']
  const labelText = computed(() => {
    if (localProps.value.componentLabel) {
      return localProps.value.componentLabel
    }
    return props.value.label
  })

  // Input
  const inputId = computed(() => {
    return useComponentId('input').value
  })
  const inputClasses = ['input-input']
  const inputAttrs = computed(() => {
    return {
      ...attrs.value,
      id: inputId.value,
      'aria-labelledby': labelId.value,
      'aria-invalid': resolveBooleanAttr(validationHelpers?.ariaInvalid?.value),
      'aria-describedby': validationHelpers?.ariaDescribedBy?.value,
      'analytics-name': props.value.analyticsName ? props.value.analyticsName : null,
    }
  })

  const inputListeners = computed(() => {
    /*
     One approach to handling the event is to override it
     https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components

     Since we are using v-model, we are actually wanting to remove the event from the listeners, since v-model is going
     to apply it. If we dont remove the event we will end up with duplicated handlers.

     There may be some scenarios where the event binding is being handled correctly, we may need to use the following;
       return {
        ...listeners.value,
        input: (value) => {
           if (value.target) {
             emit('input', value.target.value)
           } else {
             emit('input', value)
           }
         },
       }

     */
    // Remove the v-model event

    const filtered = omit(listeners.value, event)
    return {
      ...filtered,
    }
  })

  const { model } = useVModel(props.value, emit)

  const root = reactive({
    classes: rootClasses,
  })
  const wrapper = reactive({
    classes: wrapperClasses,
    validation,
  })
  const label = reactive({
    id: labelId,
    classes: labelClasses,
    text: labelText,
  })
  const input = reactive({
    id: inputId,
    classes: inputClasses,
    attrs: inputAttrs,
    listeners: inputListeners,
    model,
  })
  return reactive({
    // Helper
    root,
    wrapper,
    label,
    input,
    validationHelpers,
    // Component
    props,
    attrs,
    listeners,
    emit,
  })
}

export function useVModel(props, emit) {
  const model = computed({
    get() {
      return props.value
    },
    set(value) {
      emit('input', value)
    },
  })
  return {
    model,
  }
}

export function useFocusUserInputRef(inputSelector) {
  const userInputRef = ref(null)
  const focus = () => {
    if (userInputRef.value) {
      const $el = userInputRef.value.$el
      if ($el) {
        if (inputSelector) {
          $el.querySelector(inputSelector)?.focus()
        } else {
          $el?.focus()
        }
      }
    }
  }

  return {
    userInputRef,
    focus,
  }
}

/*
* Clean leading and trailing spaces
* @str - string to trim
*/
export function cleanTrailingSpace(str) {
  return str.replace(/^\s+|\s+$/g,'')
}

/*
* Camelize string
* @str - string to camelize
*/
export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}
