import { computed, reactive } from '@vue/composition-api'
import { useComponentId } from '@/composables/useComponent'

export function useValidationHelpers(validation) {
  const labelId = useComponentId('validation')
  const validationId = useComponentId('validation')
  const hasError = computed(() => {
    return validation && validation.$dirty && validation.$anyInvalid
  })
  const ariaDescribedBy = computed(() => {
    return validation && validation.$anyInvalid && validationId
  })
  const ariaInvalid = computed(() => {
    return !!(validation && validation.$anyInvalid)
  })
  return reactive({
    labelId,
    validationId,
    hasError,
    ariaDescribedBy,
    ariaInvalid,
  })
}
